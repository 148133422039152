$(document).ready(function(){

  $('.description-clipping-img').click(function () {
    var element = $('.' + $(this).data('reference'));
    if($('.description').hasClass('description-opacity')) {
      $('.description').removeClass('description-opacity');
      $('.team-img').removeClass('description-opacity ');
      $('.team-img').next().removeClass('description-opacity');
    }
    element.addClass('description-opacity');
    $(this).addClass('description-opacity');
    $(this).next().addClass('description-opacity');

  });

  function opacity_first_quote () {
    if ($('.description-clipping-img').length) {
      var first = $('.description-clipping-img').first();
      first.addClass('description-opacity');
      first.next().addClass('description-opacity');
      var element = $('.' + first.data('reference'));
      element.addClass('description-opacity');
    }
  }

  opacity_first_quote();
});
